
section.content {
  padding: 60px 0px;
  color: $tcc-grey;

  &.counter-list {
    background: $primary;
    color: $white;
    text-align: center;

    .counter {
      font-size: 36px;
      font-weight: bold;
    }

    .subtext {
      font-size: 18px;
    }
  }

  h1, h2 {
    font-size: 24px;
    color: $primary;
    position: relative;
    text-align: center;
    margin-bottom: 40px;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 50px;
      height: 4px;
      background: $primary;
      left: calc(50% - 25px);
    }

    &.text-left {
      &:after {
        left: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  &.bg-primary {
    color: $white;

    h2 {
      color: $white;

      &:after {
        background: $white;
      }
    }
  }

  p {
    line-height: 20px;
    font-weight: 300;
  }

  &.grey {
    background: $tcc-grey-light-2;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      position: relative;
      padding-left: 20px;

      &:after {
        position: absolute;
        content: '';
        background: $primary;
        height: 8px;
        width: 8px;
        left: 0;
        top: 7px;
      }
    }
  }

  strong {
    color: #000;
  }

  .card.news {
    cursor: pointer;
  }

  &.big-feature {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 300;
    @include media-breakpoint-down(md) {
      font-size: 13px;
    }

    span {
      padding-right: 35px;
      @include media-breakpoint-down(md) {
        padding-right: 0px;
      }
    }

    i {
      padding-right: 20px;
    }
  }

  &.webex-meetings-functions {
    h2 {
      font-family: Poppins;
      color: $white;

      &:after {
        background: $white;
      }
    }
  }

  .function-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 300;

    .function-icon {
      font-family: Poppins;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      margin-right: 45px;
      @include media-breakpoint-down(md) {
        margin-right: 25px;
      }

      span {
        padding-bottom: 10px;
      }
    }
  }
}


//fetured Service

.featured-service {
  position: relative;
  margin-bottom: 50px;
}

.featured-service .inner-box {
  position: relative;
  display: block;
}

.featured-service .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.featured-service .image-box a {
  display: block;
}

.featured-service .image-box img {
  display: block;
  width: 100%;
}

figure {
  margin: 0;
}

.featured-service .image-box .caption-box {
  position: absolute;
  left: 0px;
  bottom: -8px;
  width: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  padding: 15px 15px 15px 80px;
}

.featured-service .image-box .caption-box .title {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}

.featured-service .image-box .caption-box .title a {
  color: #ffffff;
}

.featured-service .image-box .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  font-size: 32px;
  color: #ffffff;
  background: #ED8021;
  line-height: 40px;
  padding: 10px 0px;
  text-align: center;
}

.featured-service .overlay-box {
  position: absolute;
  left: 0px;
  bottom: -100%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px 30px 20px 90px;
}

.featured-service .overlay-box {
  position: absolute;
  left: 0px;
  bottom: -100%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px 30px 20px 90px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
}

.featured-service .overlay-box .overlay-inner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  padding: 20px 30px 20px 90px;
}

.featured-service .overlay-box .overlay-content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.featured-service .overlay-box .title {
  font-size: 20px;
  color: #b2dd4c;
  line-height: 1.2em;
  margin-bottom: 5px;
}

.featured-service .overlay-box .title a {
  color: #ED8021;
}

.featured-service .overlay-box .text {
  font-size: 14px;
  color: #ffffff;
  line-height: 1.8em;
}

.featured-service .overlay-box .icon_box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 100%;
  font-size: 32px;
  color: #ffffff;
  background: #ED8021;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured-service .overlay-box .icon_box span {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  width: 100%;
}


ul.top-bar-text.float_left a {
  color: #fff;
}

.featured-service .overlay-box .text {
  text-align: justify;
  line-height: 1.5em;
}

.featured-service:hover .overlay-box {
  bottom: 0px;
}

section.triangle {
  font-family: Poppins;
  @include media-breakpoint-down(md) {
    h2 {
      font-size: 23px;
    }
  }

  p {
    font-weight: 300;
    line-height: 26px;
  }

  ul {
    li {
      padding-left: 25px;

      &:after {
        @extend .fas;
        content: "\f101";
        top: 0;
        background: none;
        width: auto;
        height: auto;
        line-height: 22px;
        color: $primary;
      }
    }
  }

  &.triangel-right {
    h2 {
      text-align: left;

      &:after {
        left: 0;
      }
    }
  }

  &.triangel-left {
    h2 {
      text-align: left;

      &:after {
        left: 0;
      }
    }
  }

  &.triangle-cable.triangle-dark {
    background-image: url('../assets/images/atradis/cable.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-neu {
    background-image: url('../assets/images/hochwasser/infrastruktur.jpg');
    background-size: auto 100% ;
    background-repeat:no-repeat ;
    color: #212529 !important;
  }
  &.triangle-datenschutzmitsicherheitspass.triangle-dark {
    background-image: url('../assets/images/atradis/datenschutz_mit_sicherheitspass.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-avaya.triangle-dark {
    background-image: url('../assets/images/atradis/avaya-bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-orni.triangle-dark {
    background-image: url('../assets/images/atradis/orni.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-desk.triangle-dark {
    background-image: url('../assets/images/atradis/desk.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-fraud.triangle-dark {
    background-image: url('../assets/images/atradis/fraud.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-cable.triangle-grey {
    background-image: url('../assets/images/atradis/number.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-mdm-concept.triangle-grey {
    background-image: url('../assets/images/atradis/laptop-display-mit-mdm-konzept-auf-landing-page.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-unify.triangle-grey {
    background-image: url('../assets/images/atradis/unify.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-cisco.triangle-grey {
    background-image: url('../assets/images/atradis/cisco.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-avaya-grey.triangle-grey {
    background-image: url('../assets/images/atradis/avaya-bg-grey.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }


  &.triangle-laptop.triangle-grey {
    background-image: url('../assets/images/atradis/laptop.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-sla.triangle-dark {
    background-image: url('../assets/images/atradis/sla.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-unify-dark.triangle-dark {
    background-image: url('../assets/images/atradis/unify-dark.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-helpdesk.triangle-grey {
    background-image: url('../assets/images/atradis/helpdesk.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-sysreport.triangle-dark {
    background-image: url('../assets/images/atradis/sysreport.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-displays.triangle-dark {
    background-image: url('../assets/images/atradis/displays.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-statistics.triangle-grey {
    background-image: url('../assets/images/atradis/statistics.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-reception.triangle-dark {
    background-image: url('../assets/images/atradis/reception.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-callanalyse.triangle-dark {
    background-image: url('../assets/images/atradis/callanalyse.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-bsi.triangle-dark {
    background-image: url('../assets/images/atradis/bsi.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
  }

  &.triangle-dsgvo.triangle-grey {
    background-image: url('../assets/images/atradis/dsgvo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-risikoanalyse.triangle-dark {
    background-image: url('../assets/images/atradis/risikoanalyse.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
  }

  &.triangle-features.triangle-dark {
    background-image: url('../assets/images/atradis/features.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-iot.triangle-dark {
    background-image: url('../assets/images/landing/iot-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-grey-darker.triangle-dark {
    background-image: url('../assets/images/atradis/triangle-grey-darker.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-mandant.triangle-grey {
    background-image: url('../assets/images/atradis/mandant.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-itsecuritydsgvo.triangle-dark {
    background-image: url('../assets/images/atradis/itsicherheit-dsgvo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-itsecurity.triangle-grey {
    background-image: url('../assets/images/atradis/itsicherheit-grey.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-cmdb-components.triangle-grey {
    background-image: url('../assets/images/atradis/wesentliche-aufgaben-des-konfigurationsmanagements.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-systemmanagement.triangle-dark {
    background-image: url('../assets/images/atradis/systemmanagement.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-configuration-management.triangle-dark {
    background-image: url('../assets/images/landing/wesentliche-aufgaben-des-konfigurationsmanagements.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-assetmanagement.triangle-grey {
    background-image: url('../assets/images/atradis/assetmanagement.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-configuration-items.triangle-grey {
    background-image: url('../assets/images/landing/cmdb-configuration-items.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-accounting-cis.triangle-dark {
    background-image: url('../assets/images/atradis/atradis-accounting-cis.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #2c3038;
    color: #fff;
    background-size: cover;
  }

  &.triangle-intelligente-erfassungssoftware.triangle-grey {
    background-image: url('../assets/images/atradis/intelligente-erfassungssoftware.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.iot-monitoring.triangle-grey {
    background-image: url('../assets/images/landing/iot-monitoring-der-gesamte-it-infrastruktur.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #222222;
    background-size: cover;
  }

  &.triangle-configuration.triangle-dark {
    background-image: url('../assets/images/atradis/configuration-items.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e0e0e0;
    color: #ffffff;
    background-size: cover;

  }

  @include media-breakpoint-down(sm) {
    &.triangle-dark {
      &[class*="triangle-"] {
        background-image: none;

      }
    }
    &.triangle-grey {
      &[class*="triangle-"] {
        background-image: none;

      }
    }
  }
}

section.content.calltoaction {
  .container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(lg) {
      justify-content: center;

    }

    strong {
      font-size: 23px;
      font-family: Poppins;
      color: #fff;
      line-height: 50px;
    }

    .btn.btn-calltoaction {
      border-radius: 0;
      background-color: #fff;
      color: $primary;
      text-transform: uppercase;
      font-size: 18px;
      font-family: Poppins;
      font-weight: bold;
      padding: 0;
      border: 0;
      line-height: 50px;
      min-width: 375px;

      &:hover {
        opacity: .7;
      }

    }

    @include media-breakpoint-down(md) {
      strong {
        font-size: 16px;
        line-height: 24px;
      }
      .btn.btn-calltoaction {
        width: 100%;
        min-width: 100%;
      }
    }

  }

  &.calltoaction-webex {
    .container {
      justify-content: center;

    }
  }
}

.p-last {
  margin-bottom: 60px;
}

.primary-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &.alert-primary-box {
    .primary-box {
      flex-direction: column;
      justify-content: start;
      margin-left: 23px;


      .primary-box-header {
        display: flex;
        min-height: 50px;

        h3 {
          font-size: 18px;
          display: inline;
        }

        i {
          font-size: 18px;
          padding-right: 7px;
        }
      }

      ul {
        li {
          &:after {
            @extend .fas;
            content: "\f101";
            top: 0;
            background: none;
            width: auto;
            height: auto;
            line-height: 22px;
            color: $white;
          }
        }
      }

    }
  }

  .primary-box {
    background-color: $primary;
    width: 350px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 25px;
    min-height: 150px;
    font-family: Poppins;
    margin-bottom: 23px;

    i {
      font-size: 45px;
      padding-right: 25px;
    }

    p {
      line-height: 20px;
      margin-bottom: 0;
    }

    &:not(:first-child) {
      margin-left: 23px;

      @include media-breakpoint-down(md) {
        margin-left: 0px;

      }
    }
  }
}

.phone-boxes {
  @extend .primary-boxes;

  .phone-box {
    @extend .primary-box;
    flex-direction: column;
    background-color: $tcc-grey-darker;
    color: $tcc-black;
    font-family: Hind;
    font-size: 16px;
    padding-left: 0;
    margin-bottom: 50px;

    .phone-box-head {
      margin-top: -50px;
      margin-bottom: 35px;
      margin-left: -10px;
      display: flex;

      i {
        padding-right: 6px;
      }
    }

    ul {
      padding-left: 25px;
      min-height: 168px;

      li {
        padding-left: 0;

        &:after {
          content: none;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 23px;
      @include media-breakpoint-down(md) {
        margin-left: 0px;
      }
    }
  }
}

.word-cloud {
  font-family: Poppins;
  font-size: 24px;
  color: #222222;

  .word-cloud-row {
    display: flex;
    margin-bottom: 25px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    @include media-breakpoint-down(md) {
      justify-content: center;
      align-content: center;
      text-align: center;
    }
  }
}

section.content {
  .atradis-list {
    color: #222222;
    font-family: Poppins;
    font-weight: 500;
    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
    }

    li {
      padding-bottom: 15px;

      &:after {
        @extend .fas;
        content: "\f00c";
        background: none;
        width: auto;
        height: auto;
        line-height: 9px;
      }
    }

    &.big-list {
      font-size: 24px;

      li {
        padding-left: 32px;

        &:after {
          line-height: 23px;
        }
      }

    }
  }
}

div.alert-box {
  background-color: $tcc-grey-light;

  .alert-body {
    padding: 36px 19px;
    min-height: 270px;

    ul {
      margin-top: 30px;

      li {
        &:after {
          @extend .fas;
          content: "\f101";
          top: 0;
          background: none;
          width: auto;
          height: auto;
          line-height: 22px;
          color: #f5821f;
        }
      }

    }

    h3 {
      padding-left: 7px;
      color: $primary;
      font-size: 18px;
      font-family: Poppins;
      display: inline;
    }

    i {
      font-family: IcoFont;
      color: $primary;
      font-size: 18px;
    }
  }

  &:hover {
    box-shadow: 0 0 25px #e3e3e3;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
  }

}

.light-grey {
  background-color: $tcc-grey-light;
}

.alert-box-white {
  padding: 20px;
  text-align: justify;
  min-height: 260px;

  h3 {
    font-family: Poppins;
    font-size: 18px;
    color: $tcc-black;
    text-align: center;
    margin-bottom: 17px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
  }
}

.productslider {
  margin-bottom: 60px;

  .product-box {
    @include media-breakpoint-down(lg) {
      h3 {
        font-size: 1rem;
      }
    }
  }

  .product-box-body {
    min-height: 195px;
    background-color: $tcc-grey-darker;
    padding: 36px 20px 20px 20px;
    @include media-breakpoint-down(lg) {
      min-height: 255px;
    }
  }
}

hr.divider {
  background-color: #fff;
  height: 2px;
  border-top: 0;
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
}

.price-info {
  margin-top: 50px;
}

.currency {
  font-family: Hind;
  font-size: 34px;
  vertical-align: top;
  padding-right: 10px;
}

.price {
  font-family: Hind;
  font-size: 50px;
  font-weight: bold;
  vertical-align: center;
}

.unit {
  font-family: Hind;
  font-size: 21px;
}

.price-box {
  h3 {
    margin-bottom: -10px;
    color: $tcc-black;
  }

  .price-box-body {
    border: solid 1px #fff;
    background-color: $tcc-grey-darker;
    color: $tcc-black;
    padding: 0 15px 20px 15px;
    box-shadow: 0 0 25px #e0e0e0;

    .price-header {
      margin: 0;
      padding: 0;

      p {
        line-height: 102px;
      }
    }

    .check-list > li {
      padding-bottom: 10px;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      padding-left: 22px;
      position: relative;

      &:before {
        @extend .far;
        content: "\f058";
        line-height: 24px;
        position: absolute;
        left: 0;
        color: #63a235;
      }

      &:after {
        content: none !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 25px;
  }

}

.contact-form {

  input {
    border-radius: 0;
    background: $tcc-grey-darker;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 55px;
    border: none;
    height: auto;

    &.form-control:focus {
      background: $tcc-grey-darker;
      border: none;
      box-shadow: none;
    }
  }

  textarea {
    background: $tcc-grey-darker;
    height: 125px;
    border-radius: 0;
    border: none;

    &.form-control:focus {
      background: $tcc-grey-darker;
      border: none;
      box-shadow: none;
    }
  }

  .custom-control {
    padding-left: 0;
  }

  .custom-control-label::after {
    background-color: $tcc-grey-darker;
    border-radius: 0;
    width: 25px;
    height: 25px;
  }

  .custom-control-label::before {
    background-color: $tcc-grey-darker;
    border-radius: 0;
    width: 25px;
    height: 25px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    box-shadow: none;
    background-color: transparent;
  }

  label.ds {
    line-height: 37px;

    @include media-breakpoint-down(md) {
      font-size: 10px;
      line-height: initial;
      padding-left: 5px;
    }
  }

  .btn-primary {
    min-width: 350px;
    @include media-breakpoint-down(md) {
      min-width: auto;
    }

  }

  select {
    background-color: $tcc-grey-darker;
    border-radius: 0;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 55px;
    height: 55px;
    border: none;

    &.form-control:focus {
      background: $tcc-grey-darker;
      border: none;
      box-shadow: none;
    }
  }
}

section {
  .contact-box {
    padding: 30px;
    margin-top: 110px;
    background-color: $tcc-grey-darker;
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    p {
      line-height: 25px;
    }

    > div {
      padding: 25px 10px;
    }
  }
}


.tcc-modal {
  .modal-dialog {
    .modal-content {
      border-radius: 0;

      &.content {
        padding-bottom: 0px;
        padding-top: 40px;
        @include media-breakpoint-down(md) {
          padding-top: 55px;
        }
      }

      .modal-body {
        padding-left: 39px;
        padding-right: 40px;
        padding-bottom: 41px;

        &.scroll {
          max-width: 99.9%;
          padding-top: 0;
          overflow: auto;
          max-height: 480px;

          thead {
            tr {
              th {
                position: sticky;
                background-color: #fff;

                top: 0;
              }
            }
          }
        }
      }

      .table {
        color: $tcc-grey;
      }

      .close {
        position: absolute;
        top: -1px;
        right: 0;
        width: 55px;
        height: 55px;
        background-color: #2c3038;
        border-radius: 0;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 24px;
        }
      }

      .modal-footer {
        border: 0;
        padding: 30px 30px 15px 30px;
        border-radius: 0;
      }
    }
  }
}

.slick-arrows {
  position: absolute;
  bottom: 80px;

  &.right {
    right: -40px;
    @include media-breakpoint-down(md) {
      right: -14px;
    }

    &:before {
      color: $tcc-grey-lighter;
      content: '\f101';
      font-family: "Font Awesome 5 Free";
      font-size: 48px;
      font-weight: 600;
      min-width: 24px;
      min-height: 24px;
      transition: 0.2s ease all;
    }

    &:hover {
      &:before {
        color: $primary;
        transition: 0.4s ease all;
      }
    }
  }

  &.left {
    left: -40px;
    @include media-breakpoint-down(md) {
      left: -14px;
    }

    &:before {
      content: '\f100';
      font-family: "Font Awesome 5 Free";
      color: $tcc-grey-lighter;
      font-size: 48px;
      font-weight: 600;
      min-width: 24px;
      min-height: 24px;
      transition: 0.2s ease all;
    }

    &:hover {
      &:before {
        color: $primary;
        transition: 0.4s ease all;
      }
    }
  }
}

.tcc-btn {
  padding: 0 27px;
  line-height: 34px;
  font-size: 16px;
  @include media-breakpoint-down(md) {
    font-size: 14px;

  }

}

.tcc-btn-wide {
  @extend .tcc-btn;
  padding: 0 40px;
}

section {
  &.triangle {
    ul {
      &.feature-list {
        margin-bottom: 40px;

        li {
          transition: 0.2s all ease;
          border-left: 0px $primary solid;
          padding-left: 0;
          margin-bottom: 14px;

          .feature-select {
            color: #fff;
            width: 100%;
            display: block;
          }

          &::after {
            content: none !important;
          }

          &:hover,
          &.active-feature {
            transition: 0.4s all ease;
            border-left-width: 4px;
            text-decoration: underline;
            padding-left: 4px;

            .feature-select {
              color: $primary;

            }
          }
        }
      }
    }

    div {
      &.feature {
        h3 {
          font-size: 24px;
          font-weight: 300;
          margin-bottom: 30px;
        }

        p {
          font-family: Hind;
          font-weight: 500;
        }

        &.selected-feature {
          color: $dark;
          @include media-breakpoint-down(sm) {
            color: $white;
            margin-top: 25px;

          }

        }
      }
    }
  }
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.cisco-table {
  max-width: 515px;
  margin: 0 auto;
}

.cisco-table li {
  padding-right: 20px;
}

.cisco-table li:before {
  position: absolute;
  content: "";
  background: #f5821f;
  height: 8px;
  width: 8px;
  right: 0;
  top: 7px;
}

.mt-1rem {
  margin-top: 1rem;
}

section {
  &.content {
    &.text-white {
      strong {
        color: #fff;
        font-weight: bold;
      }
    }

    .headline-water {
      &:after {
        content: none;
      }

      color: #fff;

    }
  }
}

section.content {
  .big-text {
    font-size: 28px;
    line-height: 37px;

    strong {
      color: #fff;
      font-weight: bold;
    }
  }
}

.carousel-caption h1 {
  &.water-title {
    background-color: #1894c9;
    width: fit-content;
    font-size: 26px;
    margin-bottom: 15px;
    padding: 5px;
    position: relative;
    z-index: 2;
    font-weight: bold;
    &:after {
      z-index: -1;
      content: "";
      display: block;
      background: #1793c7;
      position: absolute;
      width: 50px;
      height: 50px;
      -webkit-transform: rotate(
                      45deg
      );
      transform: rotate(
                      45deg
      );
      top: 11px;
      right: -25px;
    }
  }
}


/* css new Mehmet 13.08.21 */
.shadow {
  -webkit-box-shadow: 0px 15px 37px -8px rgba(2, 52, 97, 0.15) !important;
  box-shadow: 0px 15px 37px -8px rgba(2, 52, 97, 0.15) !important;
  border-radius: 5px;
}

.alert-box-white {
  h3 {
    color: #848484 !important;
    font-weight: 500 !important;
    font-size: 17px !important;
  }

  .zahlenbold {
    font-weight: 700;
    color: #f5821f !important;
  }
}


.blick {
  img {
    display: block;
    margin: 30px auto;
  }
}

.unabdingbar {
  font-size: 24px;
  text-align: center;
  color: #f5821f;
  margin-top: 55px;
  font-weight: 500 !important;
  line-height: 25px !important;
}

.button-bottom {
  margin: 25px auto 0;
}




.hochwasserbanner {
  background-image: url('../assets/images/hochwasser/hochwasser-banner.jpg') !important;

  .hochwasser-titel {
    background: #1793c7;
    padding: 10px;
    position: relative;
    width: 60%;
  }

  .hochwasser-titel::after {
    content: "";
    display: block;
    background: #1793c7;
    position: absolute;
    width: 61px;
    height: 61px;
    transform: rotate(45deg);
    top: 12px;
    right: -31px;
  }
}


.hochwasser-titel::after {
  content: "";
  display: block;
  background: #1793c7;
  position: absolute;
  width: 61px;
  height: 61px;
  transform: rotate(45deg);
  top: 12px;
  right: -31px;
}

/* Atradis XGuard - Nick */
#x-guard {
  font-size: 16px;
  h3, h1 {
    font-size: 1.75rem;
    font-weight: 300;
  }
  .text-primary {
    font-weight: bold;
  }
  .py-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .pb-18 {
    padding-bottom: 1.8rem !important;
  }
  .pt-225 {
    padding-top: 2.25rem !important;
  }
  strong {
    font-weight: 600 !important;
  }
  .mw-0 {
    min-width: 0 !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .mb-img {
    margin-bottom: 1.95rem
  }
  hr {
    border: 1px solid #e1e1e1;
  }
  header {
    background-color: $dark;
    color: #fff;
    div.container {
      padding-top: 5rem;
    }
    p {
      line-height: 1.2rem;
    }
    ul {
      list-style: none;
      padding-left: 20px;
      li::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 700;
        content: "\f058";
        line-height: 24px;
        position: absolute;
        left: 15px;
        color: $primary;
      }
    }
  }
  #data-export {
    background: $tcc-grey-light-2;
    img.shadow {
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
    .iot-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .top {
        display: flex;
        justify-content: space-between;
        p {
          text-transform: uppercase;
          color: $primary;
          font-weight: 500;
          font-size: clamp(15px, 1.5vw, 26px);
        }
      }
      .bottom {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
    }
  }
  #technology {
    background: $primary;
    color: #fff;
    h3 {
      font-weight: 300;
    }
    .image-grid {
      width: 100%;
      padding: 0 10rem;
      img {
        align-self: center;
      }
    }
  }
  #wot-2 {
    background: $tcc-grey-light-2;
  }
  #bg-shaped {
    background: url('../../images/xguard/map2.jpg');
    width: 100%;
    height: 350px;
    display: flex;
    overflow: hidden;
    position: relative;
    .pentagon {
      position: relative;
      width: clamp(100px,95vw,520px);
      border-color: #fff transparent;
      border-style: solid;
      border-width: 181px 100px 0;
      &:before {
        content: "";
        position: absolute;
        height: 0;
        left: -100px;
        bottom: 180px;
        width: clamp(100px,95vw,520px);
        border-color: #fff transparent;
        border-style: solid;
        border-width: 0 80px 180px;
      }
    }
    .pentagon-container {
      position: absolute;
      max-width: 305px;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 52%;
      z-index: 100;
      p {
        position: relative;
        color: #000;
        text-align: center;
        font-size: clamp(12px, 2.9vw, 16px);
      }
    }
  }
  #product {
    background: $tcc-grey-light-2;
    h3 {
      color: $primary;
      position: relative;
      margin-bottom: 2rem;
      font-size: 24px;
      &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 5px;
        left: 50%;
        background-color: $primary;
        transform: translateX(-50%);
        bottom: -5px;
      }
    }
    img {
      padding: 0 15px;
    }
  }
  #pre-footer {
    background: $primary;
    h3 {
      color: #fff;
      font-size: 24px;
    }
  }
  #end {
    background: #fff;
  }
  #tableaus, .spacer {
    background: $tcc-grey-light-2;
  }
}

@include media-breakpoint-down(sm) {
  #x-guard {
    .iot-bar {
      margin: 1rem 0;
      img {
        max-width: 100%;
      }
    }
    .image-grid {
      flex-direction: column;
    }
    .image-grid {
      padding: 0 3rem !important;
    }
    #tableaus .col-md-6.pl-5 {
      padding-left: 15px !important;
    }
    header div.container {
      padding-top: 3rem;
    }
  }
}

@media only screen and (max-width: 500px) {
  .hidden-xs {
    display: none;
  }
  .hochwasser-titel {
    width: 90%;
  }
  .hochwasser-titel::after {
    width: 65px;
    height: 65px;
    top: 13px;
    right: -33px;
  }
}

section.content .circle-list{
  text-align: left;
  li{
    padding-left: 30px;
    &:after{
      display: none!important;
    }
    &:before{
      position: absolute;
      content: '\f058';
      font-family: "Font Awesome 5 Free";
      font-weight: bold;
      color: #f5821f;
      left: 0;
      top: 0;
    }
  }
}
.white-listing{
  padding: 60px 0 120px 0!important;
}
.white-list{
  -webkit-box-shadow: 0px 0px 10px -3px #000;
  box-shadow: 0px 0px 10px -3px #000;
  background: #fff;
  padding: 45px;
  &.first{
    margin-right: 30px;
  }
  &.second{
    margin-left: 30px
  }
  .list-body {
    height: 100%;
    position: relative;
    padding: 30px;
    border: 2px solid #f5821f;
    color: #848484;
    .head {
      font-size: 21px;
      font-weight: bold;
      color: #f5821f;
      position: absolute;
      top: -15px;
      background: #fff;
      padding-left: 15px;
      padding-right: 15px;

      span {
        color: #000;
      }
    }
  }

}

.dark-orange{
  background: #cf6203;
  padding: 30px 45px;
  margin-top: -115px;
  margin-bottom: 45px;
  p{
    margin: 0;
    color: #fff;
    strong{
      color: #fff;
    }
  }
}

.orange-border-left{
  position: relative;
  li{
    margin-bottom: 5px;
    &:after{
      border-radius: 100%;
    }
  }
  &:before{
    content: '';
    height: 100px;
    width: 10px;
    background: #f5821f;
    position: absolute;
    left: -5px;
    top: calc(50% - 50px);
  }
}


.orange-border-right{
  position: relative;
  li{
    margin-bottom: 5px;
    &:after{
      border-radius: 100%;
    }
  }
  &:before{
    content: '';
    height: 100px;
    width: 10px;
    background: #f5821f;
    position: absolute;
    right: -5px;
    top: calc(50% - 50px);
  }
}

.mt-100{
  margin-top: 100px!important;
}

.double-line{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .md-flex-direction{
    flex-direction: column-reverse;
  }
  .double-line{
    display: none;
  }
  .mt-100{
    margin-top: 30px!important;
  }
  .white-list{
    &.first{
      margin: 0;
    }
    &.second{
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
    }
    .list-body {
      .head {
        padding: 0;
        position: relative;
      }
    }

  }
}
@media (max-width: 1182px) {
  .d-1182-none{
    display: none!important;
  }
}

@media (max-width: 767px) {
  .white-list{
    padding: 0;
    border: 0;
    .head{
      hyphens: auto;
    }
  }
  .orange-border-left{
    &:before{
      display: none;
    }
  }
  .orange-border-right{
    &:before{
      display: none;
    }
  }
}
.cta-big{
  font-size: 23px;
  font-family: Poppins;
  color: #fff;
}