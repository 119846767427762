section.content-digital {
  font-family: "Hind";
  font-size: 18px;
  line-height: 21.6px;

  p {
    font-family: "Hind";
    font-size: 18px;
    line-height: 21.6px;
    margin-bottom: 30px;
  }

  strong {
    font-weight: 600;
    color: #818181;
  }
}

.carousel-caption {
  &.digital-caption {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: "Hind";

      h1 {
        color: $white;
        font-size: 47px;
        font-weight: 600;
        position: relative;
        margin-bottom: 45px;
        font-family: "Hind";
        margin-bottom: 40px;


        &:before {
          content: '';
          position: absolute;
          top: -17px;
          left: -25px;
          background: url(../assets/images/digital/left-corner.png) no-repeat top left;
          height: 34px;
          width: 34px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -3px;
          right: -17px;
          background: url(../assets/images/digital/right-corner.png) no-repeat top left;
          height: 34px;
          width: 34px;
        }

        @include media-breakpoint-down(md) {
          font-size: 17px;
          &:before {
            width: 22px;
            background-size: contain;
          }
          &:after {
            height: 18px;
            width: 26px;
            background-size: contain;
          }
        }
      }
    }
  }

}

.content {
  .digital-headline-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .digital-headline {
      font-size: 28px;
      color: $primary;
      font-weight: 500;
      position: relative;
      font-family: "Hind";

      &.mb-d2 {
        margin-bottom: 17px;
      }

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -13px;
        background: url(../assets/images/digital/left-corner-orange.png) no-repeat top left;
        height: 20px;
        width: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        right: -11px;
        background: url(../assets/images/digital/right-corner-orange.png) no-repeat top left;
        height: 21px;
        left: initial;
        width: 21px;
      }
    }

  }

  .text-digital-grey {
    color: #2C3038;
    margin-bottom: 40px;
    line-height: 21.6px;

  }

  a.black-link {
    color: #2C3038;
    font-weight: 600;
  }

  &.advantage-banner {
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url(../assets/images/digital/foerderung.jpg);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding-top: 245px;
    padding-bottom: 245px;
    border-bottom: 10px solid $primary;

    p {
      font-family: "Poppins";
      font-size: 22px;
      line-height: 26.4px;

      .bigger-text {
        font-size: 80px;
        line-height: 96px;
        @include media-breakpoint-down(md) {
          font-size: 30px;

        }
      }
    }

    @include media-breakpoint-down(md) {
      background-size: cover;

    }
  }

  .subline {
    text-align: center;
    color: #2C3038;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .list-headline {
    color: $primary;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .list-digital {
    li {
      font-size: 18px;
      line-height: 21.6px;
      padding-bottom: 30px;
      position: relative;
      padding-left: 20px;

      &:after {
        position: absolute;
        content: "";
        background: url(../assets/images/digital/check.png);
        background-repeat: no-repeat;
        height: 11px;
        width: 15px;
        top: 4px;
        left: 0px;
      }
    }
  }

  .list-digital-alternate {
    @extend .list-digital;

    li {
      &:after {
        background: url(../assets/images/digital/quad.png);
        height: 15px;
        width: 15px;
      }
    }

  }

  &.info-banner {
    background: #2C3038 url(../assets/images/digital/i-icon.png) no-repeat center left 16%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  &.bg-which {
    .pl-60 {
      padding-left: 60px;
    }

    background-color: #2C3038;
    background-image: url(../assets/images/digital/buero.jpg);

    padding-bottom: 82px;
    border-bottom: 10px solid $primary;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 50% auto;

    h2 {
      color: #fff;
      text-align: left;
      font-size: 28px;
      line-height: 33.6px;

      &:after {
        content: none;
      }
    }

    p {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
    }

    @include media-breakpoint-down(sm) {
      background-image: none;


    }
  }

  &.bg-which {
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      background: url(../assets/images/digital/question.png);
      background-repeat: no-repeat;
      bottom: -20px;
      right: 0;
      height: 327px;
      width: 461px;
      z-index: -1;
    }
  }

  &.bg-board {
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      content: '';
      background: url(../assets/images/digital/list.png);
      background-repeat: no-repeat;
      bottom: -20px;
      right: 0;
      height: 366px;
      width: 315px;
      z-index: -1;
    }
  }

  &.bg-a {
    .container {
      position: relative;
      z-index: 1;

      &:before {
        position: absolute;
        content: '';
        background: url(../assets/images/digital/la.png);
        bottom: -81px;
        left: -100px;
        height: 138px;
        width: 158px;
        z-index: -1;
      }

      &:after {
        position: absolute;
        content: '';
        background: url(../assets/images/digital/ra.png);
        top: -81px;
        right: -70px;
        height: 138px;
        width: 158px;
        z-index: -1;
      }
    }
  }

  .img-p {
    padding-right: 25px;
  }

  &.bg-plane {
    background: url(../assets/images/digital/plane.png);
    background-repeat: no-repeat;
    background-position: left 10% center;
  }

  &.box-banner {
    background-color: #2C3038;
    background-image: url(../assets/images/digital/bbanner.jpg);
    background-repeat: no-repeat;
    background-position: top left;
    border-bottom: 10px solid $primary;
    padding-bottom: 100px;

    .digital-headline {
      color: #fff;

      &:before {
        content: "";
        position: absolute;
        top: -14px;
        left: -17px;
        background: url(../assets/images/digital/left-corner.png) no-repeat top left;
        height: 20px;
        width: 20px;
        background-size: contain;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        right: -17px;
        background: url(../assets/images/digital/right-corner.png) no-repeat top left;
        height: 34px;
        width: 34px;
      }
    }

    .product-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm) {
        overflow: hidden;
      }

      .product-box {
        background: $white;
        max-width: 310px;
        position: relative;
        z-index: 2;
        margin-bottom: 55px;

        h3 {
          color: #2C3038;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          padding-top: 18px;
          margin-bottom: 15px;
          z-index: 2;
        }

        ul {
          z-index: 2;
          list-style: none;

          li {
            &:nth-child(odd) {
              background: #EEEFEF;
            }

            &:after {
              content: none;
            }

            &:last-child {
              padding-bottom: 35px;
            }

            font-size: 18px;
            line-height: 30px;
            padding: 0 15px;
            a{
              color: #848484;
            }
          }
        }

        &:after {
          position: absolute;
          content: "";
          height: 100%;
          width: 100%;
          background: transparent;
          border: 10px solid $white;
          left: 40px;
          top: 40px;
          z-index: -1;
        }
      }
    }
  }

}

.btn-here {
  text-transform: uppercase;
}

section.content.content-digital.bg-grey.bg-a,
section.content.content-digital.bg-board {
  overflow: hidden;
}

.button-call {
  box-shadow: 0 0 6px 0px #b1b1b1;
  position: fixed;
  z-index: 99999;
  width: 100px;
  height: 100px;
  background-color: $white;
  top: 80%;
  right: 5%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  border: 0;
  font-size: 20px;
  line-height: 20px;

  i {
    font-size: 52px;
    line-height: 37px;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

section.content.news-bg {
  background-image: url(../assets/images/slider/digital/digital-banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  p{
    color: #fff;

  }
}