.btn-primary {
  color: #fff;
  padding: 12px 34px;
  border-radius: 5px;
  font-size: 16px;
  align-self: flex-start;

  &:hover {
    color: $tcc-black;
  }
}


a {
  transition: .3s ease;
}

nav[aria-label="breadcrumb"] {
  border-bottom: 1px solid $primary;
}

.breadcrumb {
  background: none;
  margin-bottom: 0;

  .breadcrumb-item + .breadcrumb-item::before {
    content: '>';
  }

  a:hover {
    color: $dark;
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }

}

.card {
  transition: .5s ease-in-out;
  border: 1px solid #f4f4f4;
  border-radius: 0;
  height: 100%;

  .card-body {
    font-size: 16px;

    .card-title {
      color: $primary;
      font-weight: 400;
      font-family: Poppins;
      font-size: 18px;
    }
  }

  &:hover {
    box-shadow: 0 15px 16px 0 rgba(0, 0, 0, 0.03), 0 0px 82px 0 rgba(0, 0, 0, 0.02);
  }
}

.text-poppins {
  font-family: Poppins;
}

.text-poppins-dark {
  @extend .text-poppins;
  color: $dark !important;
}

.bg-grey {
  background-color: $tcc-grey-eee;
}

section.content {
  .arrow-list {
    margin-bottom: 20px;

    li {
      font-weight: bold;
      font-family: Poppins;
      color: $dark;
      padding-bottom: 10px;

      &:after {
        @extend .fas;
        content: "\f101";
        top: 0;
        background: none;
        width: auto;
        height: auto;
        line-height: 22px;
        color: #f5821f;
      }
    }
    &.sf{
      li{
        font-weight: 400;
      }
    }
  }

  &.bg-dark {
    color: #fff;

    .arrow-list {
      li {
        color: #fff;

      }
    }
  }
}

.mb-20 {
  margin-bottom: 20px;

}

.mb-md-20 {
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
}

section.content {
  &.webex-features {
    h2 {
      color: $white;

      &:after {
        background: $white;
      }
    }

    .webex-feature-box-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .webex-feature-box {
        margin-right: 70px;

        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
          margin-right: auto;
          width: 100%;
          min-width: 100%;
          margin-bottom: 25px;
        }

        i {
          font-size: 50px;
        }

        .text-big {
          font-family: Poppins;
          font-weight: 300;
          font-size: 24px;
        }
      }

    }
  }
}

.mb-60 {
  margin-bottom: 60px !important;
}

.lightbox-container {
  img {
    @include media-breakpoint-down(lg) {
      padding: 15px;
    }
  }
}
