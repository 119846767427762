body {
  padding-top: 168px;
  height: 100%;
  min-height: 100%;

  nav {
    &.top-bar {
      color: #fff;

      .container {
        min-height: 56px;
      }

      a {
        color: #fff;
        line-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: $primary;
          text-decoration: none;
        }

        &.text-link {
          margin-right: 20px;

          i {
            font-size: 20px;
            color: $primary;
            margin-right: 10px;
          }
        }

        &.social-link {
          font-size: 14px;
          padding-left: 20px;
        }
      }
    }

    &.navbar {
      padding: 1rem 1rem;

      &.scrolled {
        padding: .5rem .5rem;
      }

      .navbar-brand {
        img {
          transition: ease all .5s;
          width: 70px;
        }

        &.scrolled {
          img {
            width: 35px;

          }
        }
      }

      .navbar-nav {
        align-items: center;

        .nav-item {
          .nav-link {
            color: $tcc-black;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
              text-align: center;
            }

            &:hover {
              color: $primary;
            }

            .btn {
              font-weight: bold;
              text-transform: uppercase;
              font-size: 14px;
              padding: 4px 12px;
            }
          }

          &.active {
            .nav-link {
              color: $primary;
            }
          }
        }
      }
    }
  }
}


.megamenu-li {
  position: static;
}

.megamenu {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 15px;

  p {
    font-size: 13px;
    color: #848484;
    line-height: 16px;
  }

  .dropdown-item {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      background: $tcc-black;
      height: 6px;
      width: 6px;
      left: 12px;
      top: 12px;

    }

    &.active, &:active, &:hover {
      background: none;
      color: $primary;

      &:before {
        background: $primary;
      }
    }
  }
}

.dropdown-menu {
  span.menu-headline {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  @include media-breakpoint-down(md) {
    max-height: 200px;
    overflow-y: scroll;
  }

}

.btn.btn-dark.btn-nav {
  padding: 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 32px;
  border-radius: 4px;
  font-weight: bold;

  i {
    padding-right: 5px;
  }

  &:hover {
    opacity: .7;
  }

  @include media-breakpoint-down(lg) {
    margin: auto;
  }


}