.carousel-caption {

  @extend .container;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  h1, h3 {
    font-size: 1.75rem;
  }

  font-family: Poppins;
  @include media-breakpoint-down(sm) {
    h3, h1 {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      padding-left: 22px;
      position: relative;
      font-weight: 300;

      &:before {
        @extend .far;
        content: "\f058";
        line-height: 24px;
        position: absolute;
        left: 0;
        @include media-breakpoint-down(md) {
          line-height: 19px;
          font-size: 10px;

        }

      }

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }

    }
  }

  @include media-breakpoint-down(sm) {
    left: 0;
    right: 0;
  }
}

.carousel-control-prev, .carousel-control-next {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

div[class*="bg-slider"] {
  height: 360px;
  background-position: top left;
}

.no-webp {
  .bg-slider {
    &_avaya {
      background-image: url(../assets/images/slider/avaya/header-unify-avaya.jpg);
    }

    &_start-atradis {
      background-image: url(../assets/images/slider/start/header-atradis.jpg);
    }

    &_start-webex {
      background-image: url(../assets/images/slider/start/header-webex.png);
    }

    &_start-phone {
      background-image: url(../assets/images/slider/start/header-phone.jpg);
    }
    &_start-blog {
      background-image: url(../assets/images/slider/TCC_header_blog1.jpg);
      filter: brightness(0.6);
    }

    &_webex {
      background-image: url(../assets/images/slider/webex/header-webex.png);
    }

    &_atradis {
      background-image: url(../assets/images/slider/atradis/header-atradis.jpg);
    }
    &_phone {
      background-image: url(../assets/images/slider/phone/header-phone.jpg);
    }
    &_itsm {
      background-image: url(../assets/images/slider/itsm/header-itsm.jpg);
    }
    &_impressum {
      background-image: url(../assets/images/slider/impressum/header-impressum.jpg);
    }
    &_tbg {
      background-image: url(../assets/images/slider/tbg/tbg-banner.jpg);
    }
    &_video {
      background-image: url(../assets/images/slider/webex/video-header.jpg);
    }
    &_home {
      background-image: url(../assets/images/slider/webex/home-header.jpg);
    }
    &_digital {
      background-image: url(../assets/images/slider/digital/digital-banner.jpg);
      border-bottom: 10px solid $primary;

    }
    &_iot{
      background-image: url(../assets/images/slider/iot-header.jpg);
    }
    &_cmdb{
      background-image: url(../assets/images/slider/cmdb/cmdb-bei-tcc---bei-uns-sind-Sie-in-guten-haenden-header.jpg);
      background-position:center top;
    }
    &_mdm {
      background-image: url(../assets/images/slider/start/mdm-mobile_device_management-header.jpg);
    }
  }
}

.webp {
  .bg-slider {
    &_mdm {
      background-image: url(../assets/images/slider/start/mdm-mobile_device_management-header.webp);
    }
    &_avaya {
      background-image: url(../assets/images/slider/avaya/header-unify-avaya.webp);
    }

    &_start-atradis {
      background-image: url(../assets/images/slider/start/header-atradis.webp);
    }

    &_start-webex {
      background-image: url(../assets/images/slider/start/header-webex.webp);
    }

    &_start-phone {
      background-image: url(../assets/images/slider/start/header-phone.webp);
    }
    &_start-blog {
      background-image: url(../assets/images/slider/TCC_header_blog1.jpg);
      filter: brightness(0.6);
    }
    &_atradis {
      background-image: url(../assets/images/slider/atradis/header-atradis.webp);
    }

    &_webex {
      background-image: url(../assets/images/slider/webex/header-webex.webp);
    }
    &_phone {
      background-image: url(../assets/images/slider/phone/header-phone.webp);
    }
    &_itsm {
      background-image: url(../assets/images/slider/itsm/header-itsm.webp);
    }
    &_impressum {
      background-image: url(../assets/images/slider/impressum/header-impressum.webp);
    }
    &_tbg {
      background-image: url(../assets/images/slider/tbg/tbg-banner.webp);
    }
    &_video {
      background-image: url(../assets/images/slider/webex/video-header.jpg);
    }
    &_home {
      background-image: url(../assets/images/slider/webex/home-header.jpg);
    }
    &_digital {
      background-image: url(../assets/images/slider/digital/digital-banner.webp);
      border-bottom: 10px solid $primary;
    }
    &_iot{
      background-image: url(../assets/images/slider/iot-header.jpg);
    }
    &_cmdb{
      background-image: url(../assets/images/slider/cmdb/cmdb-bei-tcc---bei-uns-sind-Sie-in-guten-haenden-header.jpg);
      background-position:center top;
    }
  }
}