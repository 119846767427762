@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "assets/icomoon.css";
@import "assets/icofont.css";
@import "partials/colors";
@import "partials/overrides";
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~slick-carousel/slick/slick";
@import "~lightbox2/src/css/lightbox.css";
@import "partials/components";
@import "partials/navigation";
@import "partials/slider";
@import "partials/content";
@import "partials/digital";
@import "partials/footer";
