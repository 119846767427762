footer {
  color: $tcc-grey;

  .footer-container {
    padding: 70px 15px 20px;

    .footer-nav {
      h3 {
        font-size: 24px;
        position: relative;
        line-height: 31px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50px;
          height: 4px;
          background: $primary;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin-top: 40px;

        li {
          position: relative;
          margin-bottom: 11px;

          a {
            padding-left: 15px;
            color: $tcc-grey;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              background: $tcc-grey;
              top: 9px;
              width: 5px;
              height: 5px;
              transition: .5s ease-in-out;
            }

            &:hover {
              text-decoration: none;
              color: $primary;

              &:before {
                background: $primary;
              }
            }
          }
        }
      }

      &.contact {
        ul {
          li {
            padding-left: 30px;

            &:before {
              content: '\f0e0';
              font-family: 'Font Awesome 5 Free';
              position: absolute;
              left: 0;
              top: 0px;
              font-size: 14px;
              transition: .5s ease-in-out;
            }

            &:nth-child(2) {
              &:before {
                @extend .fas;
                content: '\f879';
              }
            }

            &:nth-child(3) {
              &:before {
                content: '\f1d8';
              }
            }

            a {
              padding-left: 0;
              color: $primary;

              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  .footer-line {
    background: #1d1d1d;

    a {
      font-family: Poppins;
      line-height: 74px;
      color: $tcc-grey;
      font-size: 14px;
      margin-right: 25px;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        margin-right: 10px;
        line-height: normal;

      }
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}